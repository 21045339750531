import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
import {Link} from 'react-router-dom';

class FooterErp extends Component {
    render(){
        let FooterData = this.props.FooterData;
        let{fClass} = this.props;
        return(
            <footer className={`footer_area h_footer_dark ${fClass}`}>
                <div className="container">
                    <div className="row">
                        {
                            FooterData.companyWidget.map(widget=>{
                                return(
                                    <Reveal effect="fadeInLeft" duration={500} key={1}>
                                        <div className="col-lg-3 col-sm-6" >
                                            <div className="f_widget dark_widget company_widget" data-wow-delay="0.2s">
                                                <a href="index.html" className="f-logo"><img src={require ("../../img/hanacom-logo-light.png")} alt=""/></a>
                                                <p>© 2008 - 2022 HANA Communications Limited. All rights reserved.<br />Prices are in New Zealand Dollars & Exclude GST</p>
                                                <div className="f_social_icon">
                                                    {
                                                        FooterData.socialIcon.map(item =>{
                                                            return(
                                                                <a href="/" key={item.id}><i className={item.icon}></i></a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Reveal>
                                )
                            })
                        }
                        {
                            FooterData.servicesWidget.map(widget=>{
                                return(
                                    <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="f_widget dark_widget about-widget pl_70">
                                            <h3 className="f-title f_500 t_color f_size_18 mb_15">{widget.title}</h3>
                                            <ul className="list-unstyled f_list">
                                                {
                                                    widget.menuItems.map(item =>{
                                                        return(
                                                            <li key={item.id}><Link to="/">{item.text}</Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    </Reveal>
                                )
                            })
                        }
                        {
                            FooterData.businessWidget.map(widget=>{
                                return(
                                    <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="f_widget dark_widget about-widget">
                                            <h3 className="f-title f_500 t_color f_size_18 mb_15">{widget.title}</h3>
                                            <ul className="list-unstyled f_list">
                                                {
                                                    widget.menuItems.map(item =>{
                                                        return(
                                                            <li key={item.id}><Link to="/">{item.text}</Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    </Reveal>
                                )
                            })
                        }
                        {
                            FooterData.termsConditions.map(widget=>{
                                return(
                                    <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="f_widget dark_widget about-widget">
                                            <h3 className="f-title f_500 t_color f_size_18 mb_15">{widget.title}</h3>
                                            <ul className="list-unstyled f_list">
                                                {
                                                    widget.menuItems.map(item =>{
                                                        return(
                                                            <li key={item.id}><Link to="/">{item.text}</Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <br /><br />
                                            <h3 className="f-title f_500 t_color f_size_18 mb_15">Contact Us</h3>
                                            <p>
                                                General Inquiry : +64 9 320 1111<br />
                                                한국어 서비스 : +64 9 281 2171<br />
                                                Email : support@hanacom.co.nz<br />
                                            </p>
                                        </div>
                                    </div>
                                    </Reveal>
                                )
                            })
                        }                       
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterErp;