import React from 'react';
import { Form, Field } from "react-final-form";

const DomainSearchForm = (props) => {
    const renderError = ({ error, touched }) => {
        if (touched && error) {
            return (
                <div className="ui error message">
                    <div className="header">{error}</div>
                </div>
            );
        }
    };
    
    const renderInput = ({ input, label, meta }) => {
        const className = `field ${meta.error && meta.touched ? "domain_form_inner error" : "domain_form_inner"}`;
        return (
            <React.Fragment>
                <div className={className}>
                    <span>{label}</span>
                    <input {...input} className="form-control" autoComplete="off" />
                    <button className="search_btn">Search</button>
                </div>
                <div>{renderError(meta)}</div>
            </React.Fragment>
        );
    };

    const onSubmit = (formValues) => {
        props.onSubmit(formValues);
    };

    return(
        <Form
            initialValues={props.initialValues}
            onSubmit={onSubmit}
            validate={(formValues) => {
                const errors = {};

                if (!formValues.domain) {
                errors.domain = "You must enter a domain name";
                }

                return errors;
            }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="ui form error">
                    <div className="container">
                        <div className="domain_box_info">
                            <h3 className="wow fadeInUp" data-wow-delay="0.3s">Search Domain</h3>
                            <Field
                            name="domain"
                            component={renderInput}
                            label="www."
                            />
                            <ul className="list-unstyled domain_price">
                                <li>.NZ domains <span>$27.90</span></li>
                                <li>.com .net .org .biz .info<span>$23.90</span></li>
                            </ul>
                        </div>
                    </div>
                </form>
            )}
        />
    );
}

export default DomainSearchForm;