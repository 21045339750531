import React from 'react';
//import Fade from 'react-reveal/Fade';
const HostingBanner =() =>{
    return(
        <section className="hosting_banner_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-7 d-flex align-items-center">
                        <div className="hosting_content">
                            <h2 className="wow fadeInUp" data-wow-delay="0.3s">Your Website. Always up! Always on!</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.5s">Professional Cloud based hosting with 99.9% uptime guarantee!</p>
                            <a href="/#" className="hosting_btn btn_hover wow fadeInUp" data-wow-delay="0.7s">Get Started</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5"></div>
                </div>
            </div>
        </section>
    )
}
export default HostingBanner;