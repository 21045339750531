import domains from "../apis/domains";
import { TPP_ACCOUNT_NO, TPP_USER_ID, TPP_PASSWORD } from '../config/keys';

import {
  SEARCH_DOMAIN,
  REGISTER_DOMAIN,
  TRANSFER_DOMAIN
} from "./types";

export const searchDomain = (formValues) => async (dispatch) => {
  const domain = formValues.domain.split('.');
  const domainName = domain[0];
  const tld = "co.nz";
  const response = await domains.post("/server/php/apiDomainSearch.php", {
    params: {
      AccountNo: TPP_ACCOUNT_NO,
      UserId: TPP_USER_ID,
      Password: TPP_PASSWORD,
      Domain: domainName,
      Tld: tld
    }
  });

  dispatch({ type: SEARCH_DOMAIN, payload: response.data });
};

