const FooterData = {
  companyWidget: [
    {
      id: 1,
      title: "Get In Touch",
      description: "Don’t miss any updates of our new templates and extensions.!",
    }
  ],
  servicesWidget: [
    {
      id: 1,
      title: "Domains & Web Hosting",
      menuItems: [
        {
          id: 1,
          url: '#',
          text: 'Web Hosting',
        },
        {
          id: 2,
          url: '#',
          text: 'eCommerce Hosting',
        },
        {
          id: 3,
          url: '#',
          text: 'Wordpress Hosting',
        },
        {
          id: 4,
          url: '#',
          text: 'Email Hosting',
        },
        {
          id: 5,
          url: '#',
          text: 'Register Domain Names',
        },
        {
            id: 6,
            url: '#',
            text: 'Transfer Domain Names',
        },
      ],
    }
  ],
  businessWidget: [
    {
      id: 1,
      title: "Business Solutions",
      menuItems: [
        {
          id: 1,
          url: '#',
          text: 'Business Broadband',
        },
        {
          id: 2,
          url: '#',
          text: 'Voicd & Communications Services',
        },
      ],
    }
  ],
  termsConditions: [
    {
      id: 1,
      title: "Information",
      menuItems: [
        {
          id: 1,
          url: '#',
          text: 'User Agreement',
        },
        {
          id: 2,
          url: '#',
          text: 'Privacy Policy',
        },
        {
          id: 3,
          url: '#',
          text: 'Rules of Domain Registration',
        },
        {
          id: 4,
          url: '#',
          text: 'Broadband Terms & Conditions',
        }
      ]
    }
  ],
  socialIcon:[
    {
      id: 1,
      url: 'https://www.facebook.com/',
      icon: 'ti-facebook',
    },
    {
      id: 2,
      url: '#',
      icon: 'ti-twitter-alt',
    },
    {
      id: 3,
      url: '#',
      icon: 'ti-vimeo-alt',
    },
    {
      id: 4,
      url: '#',
      icon: 'ti-pinterest',
    }
  ],
  
  helpLinks: [
    {
      id: 1,
      url: '#',
      item: 'Help and Contact',
    },
    {
      id: 2,
      url: '#',
      item: 'Fees',
    },
    {
      id: 3,
      url: '#',
      item: 'Security',
    },
    {
      id: 4,
      url: '#',
      item: 'App',
    },
    {
      id: 5,
      url: '#',
      item: 'Shop',
    }
  ],
  
  about: [
    {
      id: 1,
      url: '#',
      text: 'Company',
    },
    {
      id: 2,
      url: '#',
      text: 'Leadership',
    },
    {
      id: 3,
      url: '#',
      text: 'Diversity',
    },
    {
      id: 4,
      url: '#',
      text: 'Jobs',
    },
    {
      id: 5,
      url: '#',
      text: 'Press',
    },
    {
      id: 6,
      url: '#',
      text: 'Wavelength',
    },
  ],
};
export default FooterData;