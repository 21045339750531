import _ from "lodash";
import { SEARCH_DOMAIN, REGISTER_DOMAIN, TRANSFER_DOMAIN } from "../actions/types";

const domainReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_DOMAIN:
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case REGISTER_DOMAIN:
      return { ...state, domains: null };
    case TRANSFER_DOMAIN:
      return { ...state, domains: null };
    default:
      return state;
  }
};
export default domainReducer;
