import React, { Component } from "react";
import { Router, Switch } from "react-router-dom";

/*------ Pages-----*/
import About from "../Pages/About";
import Process from "../Pages/Process";
import Team from "../Pages/Team";
import Contact from "../Pages/Contact";
import ScrollToTopRoute from "../ScrollToTopRoute";
import Home from "../Pages/Home";
import HomeHosting from "../Pages/HomeHosting";
import HomeDomain from "../Pages/HomeDomain";
import HomeBroadband from "../Pages/HomeBroadband";
import HomeVoice from "../Pages/HomeVoice";
import HomeEmail from "../Pages/HomeEmail";
import Price from "../Pages/Price";
import Faq from "../Pages/Faq";
import SignIn from "../Pages/SignIn";
import SignUp from "../Pages/SignUp";
import NotFound from "../Pages/404";
import history from '../history';

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router history={history}>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute path="/About" component={About} />
          <ScrollToTopRoute path="/Process" component={Process} />
          <ScrollToTopRoute path="/Team" component={Team} />
          <ScrollToTopRoute path="/Contact" component={Contact} />
          <ScrollToTopRoute path="/Hosting" component={HomeHosting} />
          <ScrollToTopRoute path="/Domains" component={HomeDomain} />
          <ScrollToTopRoute path="/Broadband" component={HomeBroadband} />
          <ScrollToTopRoute path="/Voice" component={HomeVoice} />
          <ScrollToTopRoute path="/Email" component={HomeEmail} />
          <ScrollToTopRoute path="/Price" component={Price} />
          <ScrollToTopRoute path="/Faq" component={Faq} />
          <ScrollToTopRoute path="/SignIn" component={SignIn} />
          <ScrollToTopRoute path="/SignUp" component={SignUp} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;