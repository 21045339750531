import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/hanacom-logo-light.png")} alt=""/>
                            <img src={require("../img/hanacom-logo-dark.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item">
                                    <Link to="/" title="Home" className="nav-link">
                                        Home
                                    </Link>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink exact title="Pages" className="dropdown-toggle nav-link" aria-expanded="false" to="/Domains#Register">Domains</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Register" className="nav-link" to='/Domains#Register'>Register</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Transfer" className="nav-link" to='/Domains#Transfer'>Transfer</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Renew" className="nav-link" to='/Domains#Renew'>Renew</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <Link title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to="#">Hosting</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink title="Web Hosting" className="nav-link" to='/Hosting'>Web Hosting</NavLink></li>
                                        <li className="nav-item"><NavLink title="Wordpress Hosting" className="nav-link" to='/Hosting#wordpress'>Wordpress Hosting</NavLink></li>
                                        <li className="nav-item"><NavLink title="eCommerce Hosting" className="nav-link" to='/Hosting#ecommerce'>eCommerce Hosting</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><Link to="/Email" title="Email" className="nav-link">Email</Link></li>
                                <li className="nav-item"><a href="https://www.hanacom.co.nz" title="Broadband" className="nav-link">Business Internet</a></li>
                                <li className="nav-item"><NavLink title="Voice" className="nav-link" to="/Voice">Voice</NavLink></li>
                                <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/Contact">Contact</NavLink></li>
                                <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/SignUp">Sign Up | Log In</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;