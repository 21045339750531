import React from 'react';
import { connect } from 'react-redux';
import { searchDomain } from '../actions';
import CustomNavbar from '../components/CustomNavbar';
import DomainSearchForm from '../components/DomainSearchForm';
import Partner from '../components/Partner';
import FooterErp from '../components/Footer/FooterErp';
import FooterData from '../components/Footer/FooterData';

class HomeDomain extends React.Component {

    onSubmit = (formValues) => {
        this.props.searchDomain(formValues);
    };

    renderList() {
        return this.props.domains.map(domain => {
            //console.log(domain);
            return (
                <div className="domain_form_result" key={domain.key}>
                    <p className="domain_list" key={domain.key}>{domain.name}.<span className="tld">{domain.tld}</span></p>
                    <button type="submit" className={ domain.result === 'OK' ? 'register_btn' : 'unavailable_btn' }>Add to cart</button>
                </div>
             );
        });
      }

    render() {
        return(
            <div className="body_wrapper">
                <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive"/>
                <section className="domain_banner_area align-items-center">
                    <DomainSearchForm onSubmit={this.onSubmit} />
                    <div className="container">
                        <div className="domain_box_info">
                            <div className="row">       
                                <div className="col-lg-12 mb_50">
                                    {this.renderList()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>
                <FooterErp FooterData={FooterData}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        domains: Object.values(state.domains)
    };
  };
  
  export default connect(
    mapStateToProps,
    { searchDomain }
  )(HomeDomain);
