import React, {Component} from 'react';

class Partner extends Component {
    render(){
        var {pClass,pClasst} = this.props;
        return(
            <section className={`${pClass}`}>
                <div className="container">
                    <div className={`partner_logo_area_four ${pClasst}`}>
                        <div className="row partner_info">
                            <div className="wow fadeInLeft" data-wow-delay="0.1s">
                                <a href=".#"><img src={require ('../img/home5/partners1.gif')} alt=""/></a>
                            </div>
                        </div>
                        <p>HANA Communications is an authorised partner of TPP Wholesale which is one of the largest and fastest growing ICANN, New Zealand and Australian accredited registrars. All domain names which requested to register through HANA Communications website will be registered with TPP Wholesale.<br /><br /></p>
                        <div className="row partner_info">
                            <div className="wow fadeInLeft" data-wow-delay="0.2s">
                                <a href=".#"><img src={require ('../img/home5/all_gray.gif')} alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Partner;